<template>
  <div id="informacoes">
    <custom-lista
      class="informacoes-lista"
      :itens="locale == 'es' ? informacoes_es : informacoes_pt"
      width="300"
      height="auto"
    />
  </div>
</template>

<script setup>
const { locale } = useI18n();

// Português
const informacoes_pt = [
  {
    imagem: "info-clinica",
    local: true,
    titulo: "+ de 100",
    subtitulo: "clínicas odontológicas em todo Brasil e uma no Uruguai.",
    class: "informacoes-cartao",
  },
  {
    imagem: "info-atendimento",
    local: true,
    titulo: "60.000 mil",
    subtitulo: "pessoas atendidas, em média, por mês",
    class: "informacoes-cartao",
  },
  {
    imagem: "info-rede",
    local: true,
    titulo: "Somos a maior ",
    subtitulo: "rede de clínicas próprias do país",
    class: "informacoes-cartao",
  },
];

// Espanhol
const informacoes_es = [
  {
    imagem: "info-clinica",
    local: true,
    titulo: "+ de 100",
    subtitulo: "clínicas dentales en todo Brasil y una en Uruguay.",
    class: "informacoes-cartao",
  },
  {
    imagem: "info-atendimento",
    local: true,
    titulo: "60.000 mil",
    subtitulo: "personas atendidas, en promedio, por mes",
    class: "informacoes-cartao",
  },
  {
    imagem: "info-rede",
    local: true,
    titulo: "Somos la red más grande",
    subtitulo: "de clínicas propias en América Latina.",
    class: "informacoes-cartao",
  },
];
</script>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Informacoes",
};
</script>
